@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Roboto';
  src: url('./assets/font/Roboto/Roboto-Regular.ttf') format('truetype');  
  font-weight: normal;
  font-style: normal;
}



@font-face {
  font-family: 'Roboto500';
  src: url('./assets/font/Roboto/Roboto-Medium.ttf') format('truetype');  
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto600';
  src: url('./assets/font/Roboto/Roboto-Bold.ttf') format('truetype');  
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto600';
  src: url('./assets/font/Roboto/Roboto-Bold.ttf') format('truetype');  
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SpaceGrotesk';
  src: url('./assets/font/Space_Grotesk/static/SpaceGrotesk-Regular.ttf') format('truetype');  
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SpaceGrotesk700';
  src: url('./assets/font/Space_Grotesk/static/SpaceGrotesk-Bold.ttf') format('truetype');  
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('./assets/font/Mulish/static/Mulish-SemiBold.ttf') format('truetype');  
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BreeSerif';
  src: url('./assets/font/Bree_Serif/BreeSerif-Regular.ttf') format('truetype');  
  font-weight: normal;
  font-style: normal;
}

body{
  height: 100vh;
}

.video-section{
  /* background-color: white; */
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(23, 18, 23, 0.62) 30%, #251D26 100%);
}

.map-background {
  background-image: url("./assets/images/footer-above-section-banner.png");
  background-size: cover;
}

html {
  scroll-behavior: smooth;
}

